import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    borderRadius: '50%',
    flexGrow: 0,
    flexShrink: 0
  },
  suspicious_activity: {
    backgroundColor: '#C22121',
    color: '#ffffff'
  },
  slow_pay: {
    backgroundColor: '#BD8D46',
    color: '#ffffff'
  },
  split_payer: {
    backgroundColor: '#F57336',
    color: '#ffffff'
  },
  stacking_history: {
    backgroundColor: '#7F1367',
    color: '#ffffff'
  },
  default_account: {
    backgroundColor: '#04756F',
    color: '#ffffff'
  },
  default_satisfied: {
    backgroundColor: '#43a047',
    color: '#ffffff'
  },
  criminal_history: {
    backgroundColor: '#1E1E20',
    color: '#ffffff'
  },
  covid_hardship: {
    backgroundColor: '#ebc700',
    color: '#ffffff'
  },
  if_client_default: {
    backgroundColor: '#777777',
    color: '#ffffff'
  },
  if_vendor_slow_pay: {
    backgroundColor: '#999999',
    color: '#ffffff'
  },
  other: {
    backgroundColor: '#225378',
    color: '#ffffff'
  },
  merchant_dispute: {
    backgroundColor: '#111111',
    color: '#ffffff'
  }
}));

const CategoryChip = props => {
  const { category } = props;

  const classes = useStyles();

  let categoryClass = classes.default_account
  switch (category){
    case 'Suspicious Activity':
      categoryClass = classes.suspicious_activity
      break
    case 'Slow Pay':
      categoryClass = classes.slow_pay
      break
    case 'Split Payer':
      categoryClass = classes.split_payer
      break
    case 'Stacking History':
      categoryClass = classes.stacking_history
      break
    case 'Default Account':
      categoryClass = classes.default_account
      break
    case 'Default Satisfied':
      categoryClass = classes.default_satisfied
      break
    case 'Criminal History':
      categoryClass = classes.criminal_history
      break
    case 'Covid-19 Hardship':
      categoryClass = classes.covid_hardship
      break
    case 'IF - Client Default':
      categoryClass = classes.if_client_default
      break
    case 'IF - Vendor Slow/No Pay':
      categoryClass = classes.if_vendor_slow_pay
      break
    case 'Other':
      categoryClass = classes.other
      break
    case 'Merchant Dispute':
      categoryClass = classes.merchant_dispute
      break
    default:
      categoryClass = classes.default_account
      break
  }

  return (
    <Chip className={categoryClass} label={category} />
  );
};

export default CategoryChip;
