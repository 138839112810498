import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { asPercent } from '../../../helpers';
import moment from 'moment';

import {
  Card,
  CardActions,
  Divider,
  Button,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  Switch,
  Checkbox,
  TextField
} from '@material-ui/core';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import typography from 'theme/typography';

const useStyles = makeStyles((theme) => ({
  root: {},
  trial_button: {
    backgroundColor: theme.palette.orange,
    color: theme.palette.white,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.orange,
      color: theme.palette.white
    }
  },
  activate_button: {
    backgroundColor: theme.palette.green,
    color: theme.palette.white,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.green,
      color: theme.palette.white
    }
  },
  noUnderline: {
    textDecoration: 'none'
  },
  contribution_container: {
    display: 'flex'
  },
  contribution_caution: {
    color: theme.palette.orange,
    fontWeight: 'bold'
  },
  contribution_ok: {
    color: theme.palette.green,
    fontWeight: 'bold'
  },
  contribution_low: {
    color: theme.palette.red,
    fontWeight: 'bold'
  },
  warning_bar: {
    backgroundColor: theme.palette.orange,
    '& p,h3': {
      color: theme.palette.white
    }
  }
}));

const Contribution = props => {
  const { className, is_dm_admin, onAccountContributionOverrideToggle, onAccountContributionRecalculate, account, account_view, ...rest } = props;
  const classes = useStyles();

  const ContributionPercentage = (props) => {
    const { val } = props;
    const percent = asPercent(val);
    let cssName = classes.contribution_caution;
  
    if (percent > 1)
      cssName = classes.contribution_ok;
    if (percent < 0.5)
      cssName = classes.contribution_low;
  
    return (
      <div className={cssName}>
        { percent }% 
      </div>
    );
  }

  const WarningBar = (props) => {
    const { account, ...rest } = props

    if (account.contribution_warning_date){
      return (
        <>
          <Grid
              item
              md={12}
              xs={12}
            >
            <Grid
              container
              spacing={3}
              className={classes.warning_bar}
            >
              <Grid
                item
                md={3}
                xs={6}
              >
                <CardContent>
                  <Grid
                    container
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        CONTRIBUTION WARNING ISSUED
                      </Typography>
                      <Typography variant="h3">{moment(account.contribution_warning_date).format('MM/DD/YYYY')}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
              >
                <CardContent>
                  <Grid
                    container
                    justifyConteny="space-between"
                  >
                    <Grid item>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        CONTRIBUTION WARNING EXPIRES
                      </Typography>
                      <Typography variant="h3">{moment(account.contribution_warning_expire_date).format('MM/DD/YYYY')}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
            <br/>
          </Grid>
        </>
      )
    }
    
    
    return (
      <>
          <Grid
              item
              md={12}
              xs={12}
            >
            <Divider />
          </Grid>
        </>
    )
  }
  

  let content = null

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <br></br>
      <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={3}
            xs={6}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    LAST 90 Days
                  </Typography>
                  <Typography variant="h3"><ContributionPercentage val={account.total_ninety_days_contribution_percentage} /></Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    LAST 30 Days
                  </Typography>
                  <Typography variant="h3"><ContributionPercentage val={account.total_month_contribution_percentage} /></Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    LAST 2 WEEKS
                  </Typography>
                  <Typography variant="h3"><ContributionPercentage val={account.total_two_week_contribution_percentage} /></Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    LAST WEEK
                  </Typography>
                  <Typography variant="h3"><ContributionPercentage val={account.total_week_contribution_percentage} /></Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <WarningBar account={account} />
          <Grid
            item
            md={12}
            xs={12}
          >
            <form
              autoComplete="off"
            >
              <CardActions>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onAccountContributionRecalculate}
                >
                  Recalculate Contribution Percentage
                </Button>
              </CardActions>
            </form>
          </Grid>
          { is_dm_admin ?
          <Grid
            item
            md={12}
            xs={12}
          >
            <Divider />
            <br></br>
            <Typography>
            <Switch
                        checked={account.contribution_override}
                        color="primary"
                        onChange={event => onAccountContributionOverrideToggle(event, account)}
                        value="true"
                      />
            
              Contribution Requirement Override
            </Typography>
            <br></br>
          </Grid>
          : null }
        </Grid>
    </Card>
  );
};


export default withErrorHandler(Contribution, axios)