import React, { useEffect, useState } from 'react';
import {connect, dispatch} from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios, {source} from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'
import {authHeaders} from '../../../helpers'

import Chart from './Chart';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const SearchCountChart = props => {
  const { search_counts } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    labels: [],
    searches: []
  })

  const getDatesFromSeachCounts = (search_counts) => {
    let dates = []
    for (var j=0; j < search_counts.length; j++){
      dates.push(moment(search_counts[j].created_at).format('MMMM YYYY'))
    }
    return dates
  }

  const getSearchLabels = (search_counts) => {
    const unique = [...new Set(search_counts.map(item => item))]
    return unique
  }

  const getCountsPerLabel = (search_counts, labels) => {
    let counts = []
    for(var i=0; i < labels.length; i++){
      let cnt = 0
      for (var j=0; j < search_counts.length; j++){
        if (search_counts[j] === labels[i]){
          cnt += 1
        }
      }
      counts.push(cnt)
    }
    return counts
  } 

  useEffect(() => {
    let labels = []
    let searches = []
    let dates = []
    dates = getDatesFromSeachCounts(search_counts)
    labels = getSearchLabels(dates)
    searches = getCountsPerLabel(dates, labels)
    setData({
      labels: labels,
      searches: searches
    })
  }, [search_counts])

  let chart = (
      <Chart
        data={data}
      />
    )
  
  return (
    <Card>
      <CardHeader
        title={`Search History`}
      />
      <Divider />
      {chart}
    </Card>
  );
};

export default withErrorHandler(SearchCountChart, axios)